@import "variables";

.article-detail {
	&-image {
		max-width: 320px;
		margin: 0 15px 15px 0;
	}

	&-date {
		color: @fontColor2;
		font-weight: 700;
	}
}
