@import "variables";

.search-item {
	border-bottom: 1px solid @borderColor;
	padding: 10px 0;

	&:last-child {
		border-bottom: none;
	}

	.highlight {
		background-color: @mainColor;
		color: white;
	}

	p {
		margin-bottom: 0;
	}
}

html.gsd-design {
	.search-item .highlight {
		background-color: @gsdMainColor;
	}
}
