@import "variables";
@import "fonts";

body, footer {
	color: @fontColor;
	font-size: 14px;
	font-family: 'Open Sans','Helvetica Neue',Helvetica,Arial,sans-serif;
	font-style: normal;
	font-weight: 400;
}

body main {
	padding-top: 20px;
	padding-bottom: 50px;
	overflow-wrap: break-word;
	word-wrap: break-word;

	@media (max-width: 768px) {
		padding-left: 10px;
		padding-right: 10px;
	}
}

.cookie-overlay {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background: rgba(0, 0, 0, 0.4);
	transition: opacity 500ms;
	z-index: 16;
	visibility: visible;
	opacity: 1;
}

.cookie-overlay.dismissed {
	visibility: hidden;
	opacity: 0;
}

#cookieBar {
	margin: 70px auto;
	padding: 20px;
	background: #fff;
	border-radius: 5px;
	width: 80%;
	max-width: 700px;
	min-width: 220px;
	position: relative;
	transition: all 5s ease-in-out;

	div {
		display: flex;
		flex: 0 0 50%;
		justify-content: space-between;
		align-items: baseline;

		@media (max-width: 768px) {
			flex: 0 0 100%;
			flex-wrap: wrap;
		}
	}

	.btn.btn-primary {
		background-color: @buttonBackground;
		border-radius: 2px;
		min-width: 150px;
		border-color: transparent !important;
		color: white !important;

		&:hover, &:active {
			background-color: @buttonBackgroundHover;
		}

		@media (max-width: 768px) {
			margin-bottom: 10px;
		}
	}
}

html.gsd-design #cookieBar {
	background-color: @gsdLightBlue;
	border-color: @gsdLightBlue;

	.btn-primary {
		color: @gsdLightBlue !important;

		&:hover, &:active {
			border-color: @gsdLightBlue;
		}
	}
}
