@import "variables";

.navbar {
	padding: 0;

	.navbar-brand {
		img {
			max-height: 50px;
		}
		img.tall-logo {
			max-height: 100px;
		}
	}

	#language-selector-wrapper {
		position: relative;
		width: 80%;

		&::after {
			content: "\f107";
			font-family: "Font Awesome 5 Free";
			font-weight: 900;
			position: absolute;
			right: 15px;
			top: 10px;
			z-index: 1;
		}
	}

	#language-selector {
		height: 40px;
		border-radius: 25px;
		border: solid 1px @borderColor2 !important;
		color: @fontColor3 !important;
		box-shadow: none !important;
		width: 100%;
		padding-left: 12px;
		outline: none;
		-webkit-appearance: none;
		-moz-appearance: none;
		position: relative;
		z-index: 2;
		background-color: transparent;

		&::-ms-expand {
			display: none;
		}
	}

	@media (min-width: 768px) {
		.input-group {
			height: 40px;

			input, button {
				border-radius: 25px;
				border-color: @borderColor2 !important;
				color: @fontColor !important;
				box-shadow: none !important;
			}

			input {
				border-top-right-radius: 0;
				border-bottom-right-radius: 0;
				border-right: none;
			}

			button {
				border-top-left-radius: 0;
				border-bottom-left-radius: 0;
				border-left: none;
				background: none !important;
			}
		}

		#searchButtonMobile {
			display: none;
		}

		#searchMobile {
			display: none;
		}
	}

	@media (max-width: 768px) {
		.navbar-brand img {
			margin-top: 10px;
			margin-bottom: 10px;
		}

		.input-group {
			input {
				display: none;
			}

			button {
				display: none;
			}
		}

		#searchButtonMobile {
			display: block;
			border: none !important;
			background: none !important;
			color: @lightBlue !important;
		}
	}
}

html.gsd-design .navbar #language-selector {
	color: @gsdLightBlue !important;
}

html.gsd-design .navbar {
	@media (max-width: 768px) {
		#searchButtonMobile {
			color: @gsdLightBlue !important;
		}
	}
}

ul.navbar-nav {
	background: @menuBackground;

	li.nav-item > a {
		text-transform: uppercase;
		font-size: 14px;
	}
}

@media (min-width: 768px) {
	ul.navbar-nav {
		position: relative;

		li.nav-item > a {
			color: @linkColor !important;
			line-height: 42px;
			font-weight: 700;
			padding: 0 20px !important;
		}

		.dropdown-menu {
			padding: 5px 8px 15px;
			box-shadow: 0 7px 10px -2px rgba(0,0,0,0.25);
			width: 100%;
			border: none;
			border-radius: 0;
			margin: 0;
			flex-wrap: wrap;


			a.dropdown-item {
				flex: 1 0 21%;
				color: @dropdownColor !important;
				text-transform: uppercase;
				font-weight: 700;
				font-size: 13px;
				line-height: 24px;
				padding: 0 5px;
				margin-top: 26px;
				margin-bottom: 4px;
				float: left;
				width: 25%;

				&:hover {
					background: none;
					text-decoration: underline;
				}

				span {
					display: none;
				}
			}
		}

		.show {
			position: static;

			.dropdown-menu {
				display: flex;
			}

			.nav-link {
				background: none;
			}
		}

		li.nav-item:hover {
			background: @linkColor !important;

			> a {
				color: white !important;
			}
		}
	}

	html.gsd-design ul.navbar-nav {
		li.nav-item > a {
			color: @gsdLightBlue !important;
		}

		li.nav-item:hover {
			background: @gsdLightBlue !important;

			> a {
				color: white !important;
			}
		}
	}
}

@media (max-width: 768px) {
	ul.navbar-nav {
		background: @dropdownBackground;

		li.nav-item {
			border-bottom: 1px solid @borderColor;

			> a {
				color: @fontColor !important;
				padding: 13px 36px 13px 15px;

				&:hover {
					color: @lightBlue !important;
				}
			}
		}

		.show .nav-link {
			font-weight: 700;
			background: none;
		}

		.dropdown-menu {
			background: @dropdownBackground;

			a {
				color: @fontColor !important;
				font-size: 14px;
				background: none !important;
				line-height: 1.5;

				&:hover {
					color: @lightBlue !important;
				}

				span {
					margin-right: 10px;
					font-size: 14px;
					line-height: 1.5;
				}
			}

			&.show {
				border: none;
				border-top: 1px solid @borderColor;
			}
		}
	}

	html.gsd-design {
		ul.navbar-nav {
			li.nav-item a:hover {
				color: @gsdLightBlue !important;
			}

			.dropdown-menu a:hover {
				color: @gsdLightBlue !important;
			}
		}
	}
}

.breadcrumb {
	margin-bottom: 0;
	padding-top: 25px;
	padding-bottom: 10px;

	.breadcrumb-item, a {
		color: @fontColor;
		font-size: 13px;
	}

	.breadcrumb-item + .breadcrumb-item::before {
		content: "\f105";
		font-family: "Font Awesome 5 Free";
		font-weight: 900;
	}
}
