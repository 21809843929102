@import "variables";

footer {
	background-color: @footerBackground;

	#footer-top {
		background-image: url('/images/footer_bg.png');
		background-size: 100% 80px;
		height: 80px;
		width: 100%;
	}

	.container {
		padding-bottom: 40px;

		.row > div {
			margin-top: 40px;
		}

		.block-title {
			font-size: 16px;
			font-weight: 400;
			color: @fontColor2;
		}

		ul {
			list-style: none;
			padding-left: 0;

			li {
				padding: 10px 0;

				&::before {
					content: "\f105";
					font-family: "Font Awesome 5 Free";
					font-weight: 900;
					margin-right: 5px;
				}

				a {
					color: @fontColor2;


					&:hover {
						color: @fontColor2Hover;
					}
				}
			}
		}

		#socialMedia {
			text-align: center;
			margin-top: 40px;

			a {
				font-size: 37px;
				padding: 10px;
				line-height: 1;
				color: #8C8C8C;
			}

			#linkedin:hover {
				color: #006699;
			}

			#youtube:hover {
				color: #C41A1E;
			}

			#instagram:hover {
				color: transparent;
				background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
				background-clip: text;
				-webkit-background-clip: text;
			}
		}
	}
}

@media (max-width: 768px) {
	footer #socialMedia {
		text-align: left !important;
	}
}

html.gsd-design {
	#footer-top {
		background: none;
		height: 40px;
	}
}
