@import "variables";

// default rules, based on rte.css
// headers are specified here as well in order to use the same rules wherever they are used on pages
h1 {
	color: @mainColor;
	font-size: 24px;
}

h2, h1.small-header, .rich-text-in-first-column h1 {
	color: @mainColor;
	font-size: 20px;
}

h3, h2.small-header {
	color: @mainColor;
	font-size: 16px;
}

h4 {
	color: @mainColor;
	font-size: 14px;
}

html.gsd-design {
	h1, h2, h3, h4 {
		color: @gsdMainColor;
	}
}

p.normal {
	color: @fontColor;
	font-size: 14px;
	font-family: 'Open Sans','Helvetica Neue',Helvetica,Arial,sans-serif;
	font-style: normal;
	font-weight: 400;
}

.sideNav {
	border: 1px solid @borderColor;
	margin-bottom: 20px;

	span {
		background-color: @sideNavBackground;
		border-bottom: 1px solid @borderColor;
		text-transform: uppercase;
		font-weight: 700;
		font-size: 13px;
	}

	.nav {
		padding: 10px 0;

		a {
			color: @fontColor !important;
		}

		a:hover {
			text-decoration: underline;
		}
	}
}

.content-line {
	height: 1px;
	width: 100%;
	margin: 50px 0;
	border: 1px solid @borderColor;
}

.link-box {
	a {
		color: @linkBoxColor !important;
		text-transform: uppercase;
		padding: 25px;
	}

	background-color: @linkBoxBackground;
	font-size: 12px;
	font-weight: 700;
}

.product-page .content-line, .instrument-page .content-line {
	margin-top: 10px;
	margin-bottom: 25px;
}

.page-content {
	.banner-image {
		margin-bottom: 50px;
	}

	h1, h2, h3, h4, h5 {
		color: @linkColor;
	}

	a {
		color: @lightBlue;
	}

	a:visited {
		color: @lightBlue;
	}

	.btn.btn-primary {
		background-color: @buttonBackground;
		border-radius: 2px;
		min-width: 150px;
		border-color: transparent !important;
		color: white !important;

		&:hover, &:active {
			background-color: @buttonBackgroundHover;
		}
	}
}

html.gsd-design .page-content {
	a {
		color: @gsdLightBlue;
	}

	a:visited {
		color: @gsdLightBlue;
	}
}

.product-subcategories, .products.list, .services, .instruments {
	h2 {
		color: @linkColor;
	}

	.product-subcategory, .product, .service {
		background-color: @productSubcategoryBackground;
		height: 100%;
		border-radius: 2px;

		img {
			margin-right: 25px;
			max-width: 50px;
			height: 50px;
		}

		a {
			color: @productSubcategoryColor;
			text-transform: uppercase;
			font-weight: 700;
			font-size: 12px;
			padding: 25px;
			display: inline-block;

			&:hover {
				color: @productSubcategoryColor;
			}
		}
	}

	.list-group-flush .list-group-item {
		padding-top: 0;
		padding-bottom: 0;
		border: none;

		&:nth-child(even) {
			background-color: transparent;

			a {
				background-color: transparent;
			}
		}
	}

	.service a {
		width: 100%;
		height: 65px;
	}
}

html.gsd-design .product-subcategories, .services, .instruments h2 {
	color: @gsdLightBlue;
}

.products:not(.list) .product, .instruments .instrument {
	text-align: center;
	margin-top: 30px;

	a {
		color: @fontColor;

		img {
			display: block;
			margin: auto;
			margin-bottom: 10px;
		}
	}
}

.pagination-nav {
	margin-top: 40px;

	.page-item {
		margin-right: 7px;
	}

	.page-item .page-link {
		border-radius: 0 !important;
	}

	.page-item:not(.disabled) .page-link {
		color: @lightBlue;
	}

	.page-item.active .page-link {
		background-color: @lightBlue;
		border-color: @lightBlue;
		color: white;
	}
}

html.gsd-design .pagination-nav {
	.page-item:not(.disabled) .page-link {
		color: @gsdLightBlue;
	}

	.page-item.active .page-link {
		background-color: @gsdLightBlue;
		border-color: @gsdLightBlue;
	}
}

.product-page, .instrument-page {
	img {
		max-width: 95%;
		margin-bottom: 20px;
	}

	h1 {
		font-size: 28px;
		font-weight: 600;
	}

	h2 {
		font-size: 20px;
		color: @lightBlue;
		margin-bottom: 20px;
		margin-top: 20px;
	}

	.product-description table, instrument-description table {
		float: none !important;
	}

	.product-attachments {
		clear: both;

		li {
			line-height: 30px;

			a {
				font-weight: bold;

				span {
					font-size: 20px;
					vertical-align: middle;
					color: @fontColor;
				}
			}
		}
	}

	.product-gallery, .instrument-gallery {
		img {
			margin-bottom: 20px;
			cursor: pointer;
		}

		.modal-dialog {
			@media (min-width: 768px) {
				max-width: 70%;
			}

			height: calc(100% - 3.5rem);
		}
	}
}

html.gsd-design .product-page, .instrument-page {
	h2 {
		color: @gsdLightBlue;
	}
}

.notfound-page {
	h1 {
		font-size: 72px;
	}
}

.rich-text img {
	max-width: 100%;
	height: auto !important;
}

.accordion-title {
	cursor: pointer;
}

.accordion-title h2 {
	font-size: 16px;
}

.accordion-title h2:hover {
	text-decoration: underline;
}

.block-title h1 {
	font-size: 18px;
	font-weight: bold;
}

.home-description-banner {
	margin-top: 1rem;
	margin-bottom: 2rem;

	& > .umb-grid > .grid-section > *:not(:last-child) {
		margin-bottom: 1rem;
	}
}

.umbraco-forms-field.multiplechoice .checkboxlist {
	display: flex;
	width: 100%;
	flex-wrap: wrap;

	input {
		margin-top: 4px;
		flex: 5%;
	}

	label {
		flex: 95%;
	}
}

.umbraco-forms-field input.form-control[type=checkbox] {
	width: 5%;
}

.umbraco-forms-label {
	max-width: none;
}
