@import "variables";

.subscription {
	&-image {
		width: 100px;
		height: 100px;
	}

	&-icon {
		border: none;
		background: @lightBlue url('/images/ico_subscribe.svg') no-repeat center;
		background-size: 25px;
		width: 35px;
		height: 35px;
		padding: 5px;
		cursor: pointer;
	}

	&-email-box {
		padding: 5px;
	}

	&-form-details {
		width: 300px;
	}

	&-token-box {
		display: none;
	}
}

html.gsd-design .subscription {
	&-icon {
		background-color: @gsdLightBlue;
	}
}
