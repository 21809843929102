@import "variables";

.home {
	&-article {
		&-date {
			color: @fontColor2;
			font-weight: 700;
			margin: 0;
		}

		&-image {
			max-width: 200px;
			max-height: 150px;
		}
	}
}

@media (max-width: 768px) {
	.umb-grid .container {
		padding: 0;
	}
}

.gray-banner {
	background-color: @bannerBackground;
	color: @bannerColor;
	padding: 1rem 1rem 0 1rem;
}

.yellow-banner {
	background-color: @yellowBannerBackground;
	color: @yellowBannerColor;
	padding: 1rem 1rem 0 1rem;
}

.odd-banner-row, .even-banner-row {
	box-shadow: inset 0px 0px 18px 0px rgba(0,0,0,0.3);

	.container {
		max-width: 100%;
		padding: 0;

		.row {
			margin: 0;
		}
	}

	.col-md-6 {
		padding: 0;
	}

	.col-md-6 div {
		height: 100%;
	}

	img {
		object-fit: cover;
		width: 100%;
		height: 100%;
	}

	a {
		text-decoration: none;
		background-color: @fontColor3;
		min-width: 150px;
		border-color: transparent !important;
		color: #fff !important;
		display: inline-block;
		font-weight: 400;
		text-align: center;
		white-space: nowrap;
		vertical-align: middle;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		border: 1px solid transparent;
		padding: 0.375rem 0.75rem;
		font-size: 1rem;
		line-height: 1.5;
		border-radius: 0.25rem;
		transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
	}
}

.odd-banner-row .col-md-6:first-child {
	padding: 5%;
}

.even-banner-row .col-md-6:last-child {
	padding: 5%;
}

@media (max-width: 768px) {
	.odd-banner-row .col-md-6:first-child {
		order: 2;
	}
}
